import React, {useEffect, useState} from "react";
import "./Laboratory.css";
import {getLaboratoryRecords} from "../../store/laboratory/laboratorySlice";
import {useDispatch, useSelector} from "react-redux";
import AdminHeader from "../../components/headers/admin_header/AdminHeader";
import {DateRangePicker} from "rsuite";
import {afterToday, allowedMaxDays, combine} from "rsuite/cjs/DateRangePicker/disabledDateUtils";
import LoadingSpinningCup from "../loading/Loading";

const LaboratoryPage = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [dateRange, setDateRange] = useState(null);
    const [screenParams, setScreenParams] = useState({});
    const laboratoryRecords = useSelector((state) => state.laboratory.records);

    useEffect(() => {
        const fetchRecords = async () => {
            setIsLoading(true);

            const today = new Date();
            const thirtyDaysAgo = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);

            const defaultParams = {
                start_date: thirtyDaysAgo.toISOString().split("T")[0],
                end_date: today.toISOString().split("T")[0],
            };

            setScreenParams(defaultParams);

            await dispatch(getLaboratoryRecords({ defaultParams }));
            setIsLoading(false);
        };

        fetchRecords();
    }, [dispatch]);

    const handleDateRangeChange = async (range) => {
        if (range) {
            const [start, end] = range;
            const updatedParams = {
                start_date: start.toISOString().split("T")[0],
                end_date: end.toISOString().split("T")[0],
            };

            setDateRange(range);
            setScreenParams(updatedParams);
            setIsLoading(true);

            await dispatch(getLaboratoryRecords({defaultParams: updatedParams}));
            setIsLoading(false);
        }
    };

    return (
        <div className="laboratory-page-container">
            <div className="screens-header-container">
                <AdminHeader />
            </div>

            <div className="filter-container">
                <DateRangePicker
                    showOneCalendar
                    disabledDate={combine(allowedMaxDays(365), afterToday())}
                    appearance="default"
                    placeholder="Select Date Range..."
                    value={dateRange}
                    onChange={handleDateRangeChange}
                    ranges={[
                        {
                            label: "Last Week",
                            value: [
                                new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
                                new Date(),
                            ],
                        },
                        {
                            label: "Last Month",
                            value: [
                                new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
                                new Date(),
                            ],
                        },
                        {
                            label: "Last 3 Months",
                            value: [
                                new Date(Date.now() - 90 * 24 * 60 * 60 * 1000),
                                new Date(),
                            ],
                        },
                    ]}
                />
            </div>

            <div className="screens-body-container">
                {isLoading && <LoadingSpinningCup />}

                <div className="records-table">
                    <div className="records-header">
                        <div>Specimen ID</div>
                        <div>Collected On</div>
                        <div>Client Name</div>
                        <div>Location Name</div>
                        <div>Organization Name</div>
                        <div>Product Name</div>
                        <div>Service Level</div>
                    </div>
                    <div className="specimens-laboratory-records">
                        {laboratoryRecords.map((record, index) => (
                            <div key={index} className="specimens-laboratory-records-row">
                                <div>{record.device_id || "N/A"}</div>
                                <div>{record.collected_on || "N/A"}</div>
                                <div>{record.client_name || "N/A"}</div>
                                <div>{record.location_name || "N/A"}</div>
                                <div>{record.organization_name || "N/A"}</div>
                                <div>{record.product_name || "N/A"}</div>
                                <div>{record.service_level_description || "N/A"}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LaboratoryPage;

