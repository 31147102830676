import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    fetchLaboratoryBillingExport,
    fetchLaboratoryBillingRecords,
    fetchExportStatus,
    fetchLaboratoryRecords
} from '../../services/api/v1/laboratoryService';

// Async thunk for fetching laboratory billing records
export const getLaboratoryBillingRecords = createAsyncThunk(
    'laboratory/getLaboratoryBillingRecords',
    async (params) => {
        if (params.jobId) {
            return await fetchExportStatus(params.jobId);
        } else {
            const dateRange = params.filters.date_range;
            const accessToken = sessionStorage.getItem("accessToken");
            return await fetchLaboratoryBillingRecords(dateRange, accessToken);
        }
    }
);

// Async thunk for fetching laboratory billing records
export const getLaboratoryRecords = createAsyncThunk(
    'laboratory/getLaboratoryRecords',
    async ({defaultParams}) => {
        const accessToken = sessionStorage.getItem("accessToken");
        const formData = new FormData();
        formData.append('start_date', defaultParams.start_date);
        formData.append('end_date', defaultParams.end_date);

        console.log("formData", formData);
        return await fetchLaboratoryRecords(formData, accessToken);
    }
);

// Async thunk for initiating laboratory billing export
export const getLaboratoryBillingExport = createAsyncThunk(
    'laboratory/getLaboratoryBillingExport',
    async (params) => {
        const dateRange = params.filters.date_range;
        const accessToken = sessionStorage.getItem("accessToken");
        return await fetchLaboratoryBillingExport(dateRange, accessToken);
    }
);

// Initial state of the laboratory slice
const initialState = {
    records: [],
    laboratoryRecords: [],
    status: 'idle', // 'idle', 'loading', 'succeeded', 'failed'
    error: null,
    exportStatus: 'idle' // 'idle', 'pending', 'succeeded', 'failed'
};

// Laboratory slice
const laboratorySlice = createSlice({
    name: 'laboratory',
    initialState,
    reducers: {
        clearLaboratoryBillingData: (state) => {
            state.records = [];
            state.status = 'idle';
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLaboratoryBillingRecords.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getLaboratoryBillingRecords.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.records = action.payload || [];
            })
            .addCase(getLaboratoryBillingRecords.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(getLaboratoryBillingExport.pending, (state) => {
                state.exportStatus = 'pending';
            })
            .addCase(getLaboratoryBillingExport.fulfilled, (state, action) => {
                state.exportStatus = 'succeeded';
            })
            .addCase(getLaboratoryBillingExport.rejected, (state, action) => {
                state.exportStatus = 'failed';
                state.error = action.error.message;
            })
            .addCase(getLaboratoryRecords.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getLaboratoryRecords.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.records = action.payload || [];
            })
            .addCase(getLaboratoryRecords.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export const { clearLaboratoryBillingData } = laboratorySlice.actions;

export default laboratorySlice.reducer;
